<template>
    <input type="search" placeholder="Search Incident..." class="form__input" @click="showRelatedIncidents = true">

    <ul class="hotel-embargo-add__incidents-list" v-if="showRelatedIncidents">
        <li class="hotel-embargo-add__incident-item" v-for="incident in incidentsId" :key="incident.id"
            @click="selectIncident(incident)">
            <a href="#">{{ incident.incident_number }}</a>
        </li>
    </ul>

    <div class="hotel-embargo-add__selected-incidents" v-if="selectedIncidents.length">
        <span class="hotel-embargo-add__selected-incident" v-for="incident in selectedIncidents" :key="incident.id">
            {{ incident.incident_number }}

            <img src="@/assets/icons/vuesax/linear/close-circle-red.svg" alt="" @click="removeIncident(incident)">
        </span>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { hotelEmbargoServices } from '../Services/HotelEmbargoServices.js'

const { getIncidentsService } = hotelEmbargoServices()
const showRelatedIncidents = ref(false)
const incidentsId = ref([])
const selectedIncidents = ref([])
const emits = defineEmits(['setIncidents'])

watch(showRelatedIncidents, (isShowing) => {
    if (isShowing) setIncidents()
})

watch(selectedIncidents.value, (incidents) => { 
    emits('setIncidents', incidents) 
})

async function getIncidents() {
    const response = await getIncidentsService()

    if (response.hasErrors) return console.error(`Error getting incidents list: ${response.message}`)

    return response.data
}

function selectIncident(incident) {
    // * Add incident in selectedIncidents array
    selectedIncidents.value.push({ ...incident })

    // * Close incidents list content
    showRelatedIncidents.value = false
}

function removeIncident(incident) {
    // * Find index incident in selectedIncidents 
    const findIndexIncident = selectedIncidents.value.findIndex(i => i.id === incident.id)

    // * Remove incident in selectedIncidents array
    selectedIncidents.value.splice(findIndexIncident, 1)
}

async function setIncidents() {
    const incidentsList = await getIncidents()

    incidentsId.value = []

    incidentsList.data.forEach(incident => {
        const findSelectedIncident = selectedIncidents.value.find(i => i.id === incident.id)

        if (!findSelectedIncident) incidentsId.value.push({ ...incident })
    })
}
</script>