<template>
    <form class="form" @submit.prevent="addIncident">
        <div class="form__inputs-content">
            <div class="form__input-form">
                <label class="form__input-label form--required">Individual Name</label>

                <input type="text" placeholder="Full Name" class="form__input" v-model="hotelEmbargo.fullName">

                <div class="form__input-error" v-if="errors.fields.fullName">
                    {{ errors.fields.fullName[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Reported To Law Enforcement?</label>

                <select class="form__input" v-model="hotelEmbargo.reportedToPolice">
                    <option value="">Select Option</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>

                <div class="form__input-error" v-if="errors.fields.reportedToPolice">
                    {{ errors.fields.reportedToPolice[0] }}
                </div>
            </div>

            <div class="form__input-form" v-if="hotelEmbargo.reportedToPolice === '1'">
                <label class="form__input-label form--required">Name of Police Department</label>

                <input type="text" placeholder="Name of Police Department" class="form__input"
                    v-model="hotelEmbargo.namePoliceDepartment">

                <div class="form__input-error" v-if="errors.fields.namePoliceDepartment">
                    {{ errors.fields.namePoliceDepartment[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Reported to All Staff?</label>

                <select class="form__input" v-model="hotelEmbargo.reportedToStaff">
                    <option value="">Select Option</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>

                <div class="form__input-error" v-if="errors.fields.reportedToStaff">
                    {{ errors.fields.reportedToStaff[0] }}
                </div>
            </div>

            <div class="form__input-form hotel-embargo-add__related-incident">
                <label class="form__input-label">Related Incidents</label>

                <SearchIncidentsInput @set-incidents="setSelectedIncidents"/>
            </div>

            <div class="form__input-form">
                <label class="form__input-label">Reason</label>

                <textarea rows="5" class="form__input" placeholder="Short description of incident"
                    v-model="hotelEmbargo.reason"></textarea>

                <div class="form__input-error" v-if="errors.fields.reason">
                    {{ errors.fields.reason[0] }}
                </div>
            </div>

            <div class="form__buttons">
                <button class="button button--success">
                    Add Hotel Embargo
                </button>
            </div>
        </div>
    </form>
</template>

<script setup>
import { reactive } from 'vue'
import { hotelEmbargoServices } from '../Services/HotelEmbargoServices.js'
import { useRouter } from 'vue-router'
import SearchIncidentsInput from './SearchIncidentsInput.vue'
import { sweetAlerts } from '../../../helpers/SweetAlert.js'

const { sweetSuccess } = sweetAlerts()
const { addHotelEmbargoService, errors } = hotelEmbargoServices()
const router = useRouter()
const hotelEmbargo = reactive({
    fullName: '',
    hkDepartmentInvolved: 'Pending...',
    reportedToPolice: '',
    namePoliceDepartment: '',
    reportedToStaff: '',
    reason: '',
    incidents: []
})

async function addIncident() {
    const response = await addHotelEmbargoService(hotelEmbargo)

    if (response.hasErrors) return console.error(`Error adding hotel embargo: ${response.message}`)

    router.push('/')

    return sweetSuccess(response.data.message)
}

function setSelectedIncidents(incidents) {
    hotelEmbargo.incidents = []

    hotelEmbargo.incidents.push(...incidents)
}
</script>